import {Component} from "react";
import './qr.css';


class Qr extends Component {

    render() {
        return (
            <div  >
                <div id="content" style={{background:"#558ff6"}}>
                    <div className="site-wrapper" style={{position: "relative"}}>
                        <h2 style={{
                            position: "absolute",
                            top: "0.75em",
                            margin: 0,
                            left: "0.75em",
                            fontWeight: 600,
                            letterSpacing: "0.05em",
                            fontSize: "1.5em"
                        }}
                           >

                            QR code reader / QR code generator
                        </h2>
                        <div className="site-wrapper-inner">
                            <div id="header">
                                <div className="device-container">
                                    <img
                                        src="https://flycricket-screenshots.s3.amazonaws.com/4095f76f7fac473f9d297f9d146e22a3/medium/e8890755-49bd-4333-903f-6174292c0c42"
                                     />
                                </div>
                                <div className="text-container">
                                    <div className="tc-table">
                                        <div style={{display:"table-cell",verticalAlign:"middle"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <footer>
                        <div id="attribution">
                            <p>App <a href="/QR/privacy">Privacy Policy</a></p>
                        </div>
                    </footer>
                </footer>


            </div>
        )
    }
}

export default Qr;