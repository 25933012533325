import './App.css';
import Qr from "./qr";
import {Route, Routes,} from "react-router-dom";
import Privacy from "./privacy";
import Sudoku from "./sudoku";
import CryptoPortfolioPolicy from "./cryptoPortfolioPolicy";



function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/QR/privacy" element={<Privacy  qr={true}/>} exact/>
            <Route path="/QR" element={<Qr/>} exact />
            <Route path="/sudoku" element={<Sudoku/>} exact />
            <Route path="/sudoku/privacy" element={<Privacy qr={false}/>} exact/>
            <Route path="/crypto_portfolio/privacy" element={<CryptoPortfolioPolicy/>} exact/>
        </Routes>
    </div>
  );
}

export default App;
