import {Component} from "react";
import './qr.css';


export default class Sudoku extends Component {

    render() {
        return (
            <div>
                <div id="content" style={{background: "#558ff6"}}>
                    <div className="site-wrapper" style={{position: "relative"}}>
                        <h2 style={{
                            position: "absolute",
                            top: "0.75em",
                            margin: 0,
                            left: "0.75em",
                            fontWeight: 600,
                            letterSpacing: "0.05em",
                            fontSize: "1.5em"
                        }}
                        >

                            Sudoku
                        </h2>
                        <div className="site-wrapper-inner">
                            <div id="header">
                                <div className="device-container">
                                    <img
                                        src="https://flycricket-screenshots.s3.amazonaws.com/b37424e4b9d045f787b3b40978490906/medium/13205f8a-e25b-4f36-a6ee-ed1291542c78"
                                     />
                                </div>
                                <div className="text-container">
                                    <div className="tc-table">
                                        <div style={{display: "table-cell", verticalAlign: "middle"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <footer>
                        <div id="attribution">
                            <p>App <a href="/sudoku/privacy">Privacy Policy</a></p>
                        </div>
                    </footer>
                </footer>


            </div>
        )
    }
}